export const OrderDeprecation = () => {
  return (
    <div className="bg-background min-h-page font-neo-grotesque">
      <main className="bg-white px-20 py-8 max-w-page-content mx-auto min-h-page">
        <section>
          <h1 className="my-4 text-2xl font-bold text-black">
            On-Demand is migrating to a new platform
          </h1>
          <p className="mt-2">
            Voltage Park On-Demand is migrating to a new platform. We will no
            longer be accepting new orders through this dashboard.
          </p>
          <p className="mt-2">
            You can still manage your existing orders and accounts here.
          </p>

          <h2 className="my-4 text-xl font-bold text-black">
            Get started on the new On-Demand platform
          </h2>
          <p className="mt-2">
            To rent H100 GPUs{" "}
            <a
              className="underline"
              href="https://dashboard.voltagepark.com/signup"
            >
              create a new account
            </a>{" "}
            on our{" "}
            <a className="underline" href="https://dashboard.voltagepark.com/">
              new On-Demand dashboard
            </a>
            .
          </p>
          <p className="mt-2">
            For assisstance transferring your balance or running orders to the
            new On-Demand platform, or for any other questions you may have,
            email{" "}
            <a className="underline" href="mailto:support@voltagepark.com">
              support@voltagepark.com
            </a>
            .
          </p>

          <div className="mt-8 text-center">
            <a
              className="px-2 md:px-6 pt-3.5 pb-2.5 h-10 text-[0.9375rem] bg-[#CBFC55] rounded-[1.25rem] border border-black"
              href="https://dashboard.voltagepark.com/"
            >
              New On-Demand
            </a>
          </div>
        </section>
      </main>
    </div>
  );
};
