import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import moment from "moment";

import { About } from "./pages/About";
import { AxiosInterceptor } from "./utils/AxiosInterceptor";
import { Instances } from "./pages/Instances";
import { ContactUs } from "./pages/ContactUs";
import { Dashboard, DashboardPathnames } from "./pages/Dashboard";
import { Footer } from "./Components/Footer";
import { HowItWorks } from "./pages/HowItWorks";
import { MaintenanceNotice } from "./Components/MaintenanceNotice";
import { Navbar } from "./Components/Navbar";
import { Notification } from "./Components/Notification";
import { RedirectAfterLogin } from "./pages/RedirectAfterLogin";
import { Settings, SettingsPathnames } from "./pages/Settings";
import { AuthProvider } from "./Components/auth/AuthProvider";
import { ProtectedRoute } from "./Components/auth/ProtectedRoute";
import { openSocket } from "./websockets/websocket";
import { appVersion, isDev } from "./utils/env";
import { ScrollToTop } from "./utils/ScrollToTop";
import { Unsubscribe } from "./pages/Unsubscribe";
import { Orders } from "./pages/Orders";
import { Checkout } from "./pages/Checkout";
import { CustomerPortal } from "./pages/CustomerPortal";
import { Banner } from "./Components/Banner";
import { OrderDeprecation } from "./pages/OrderDeprecation";

// show version in console
console.log("VP Auctions Version: ", appVersion);

// enable LogRocket logging on the dev site
if (isDev) {
  LogRocket.init("9ilqhu/flopauction-dev");
  setupLogRocketReact(LogRocket);
}

// we need to override the default moment locale's behavior globally
moment.updateLocale("en", {
  relativeTime: {
    m: "%d minute",
    h: "%d hour",
    d: "%d day",
    M: "%d month",
    y: "%d year",
  },
});

export const App = () => {
  openSocket();
  return (
    <Router>
      <AuthProvider>
        <AxiosInterceptor />
        <ScrollToTop />
        <Banner />
        <MaintenanceNotice />
        <Notification />
        <Navbar />
        <Switch>
          {Object.values(DashboardPathnames).map((path) => (
            <ProtectedRoute key={path} path={path} component={Dashboard} />
          ))}
          {Object.values(SettingsPathnames).map((path) => (
            <ProtectedRoute key={path} path={path} component={Settings} />
          ))}

          <ProtectedRoute path="/instances" component={Instances} />
          <ProtectedRoute path="/orders" component={Orders} />

          <ProtectedRoute path="/checkout" component={Checkout} />
          <ProtectedRoute path="/customer-portal" component={CustomerPortal} />

          <Route path="/unsubscribe" component={Unsubscribe} />

          <Route path="/redirect-after-login" component={RedirectAfterLogin} />

          <Route path="/about" component={About} />
          <Route path="/how-it-works" component={HowItWorks} />
          <Route path="/contact-us" component={ContactUs} />
          {/* Should be last as it is a catch all for all the non existing paths */}
          <Route path="/" component={OrderDeprecation} />
        </Switch>
        <Footer />
      </AuthProvider>
    </Router>
  );
};
